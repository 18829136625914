<template>
    <ColoredStatus
        v-if="integrationCRM"
        :class="{ wrap, 'wrap-option': true }"
        :type="coloredStatus(integrationCRM.isLaunched)">
        {{ integrationCRM.crm.name }} &mdash;
        <UserOutput :user="integrationCRM.advertiser"></UserOutput>
    </ColoredStatus>
    <span v-else>
        {{ $options.methods.formatEmptyString(null) }}
    </span>
</template>

<script>
  import ColoredStatus from "@/components/Common/ColoredStatus.vue";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  
  export default {
    name: "IntegrationCRMOutput",
    components: { ColoredStatus, UserOutput },
    props: {
      integrationCRM: {
        type: Object,
        default: null
      },
      wrap: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      coloredStatus (status) {
        return status ? "is-success" : "is-danger";
      }
    }
  };
</script>

<style scoped lang="scss">
  .wrap {
    ::v-deep {
      span[class="content"] {
        white-space: wrap;
      }
    }
  }
  .wrap-option {
    display: flex;
  }
</style>
